export const LOCAL_SERVING_MODULE_STRINGS = {
    INITIALIZING:'Initialising environment...',
    DOWNLOAD: 'Local environment not ready. Please download the model:',
    NEED_DATA: 'No results available. Waiting for data.',
    WEBASSEMBLY_DELETE_ERROR: 'Download error: Error deleting old WebAssembly files.',
    WEBASSEMBLY_SAVE_ERROR: 'Download error: Error saving WebAssembly files.',
    ONNX_SAVE_ERROR: 'Download error: Error saving ONNX model.',
    INITIALIZATION_ERROR_WEBASSEMBLY: 'Initialisation error: WebAssembly artefacts issue.',
    INITIALIZATION_ERROR_ONNX: 'Initialisation error: ONNX-Runtime session error.',
    INFERENCE_ERROR: 'Model inference error. Consider updating calibration data.'
};

export const SERVING_MODULE_STRINGS = {
    NEED_DATA: 'No results available. Waiting for data.',
    REQUEST_ERROR: 'Request error: ',
    CONNECTION_ERROR: 'Connection error: '
};