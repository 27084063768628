<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> {{ props.modulTitle }} </ion-card-title>
      <ion-card-subtitle> Generates Data for Testing and Demonstration </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content> </ion-card-content>
    <ion-button fill="clear" @click="generateData">Generate</ion-button>
  </ion-card>
</template>

<script setup>
import { randomInt } from "@/utils/math";
import { events } from "@/utils/events";
import { randomColorArray } from "@/utils/color";
import { dataMessage } from "@/messages/dataMessage";

const props = defineProps({
  
  // What message to listen for
  modulTitle: {
    type: String,
    default: "Data Generator",
  },

  rows: {
    type: Number,
    default() {
      return 4;
    },
  },
  cols: {
    type: Number,
    default() {
      return 3;
    },
  },
  lowerBound: {
    type: Number,
    default() {
      return 0;
    },
  },
  upperBound: {
    type: Number,
    default() {
      return 1024;
    },
  },
  additionalFields: {
    type: String,
    default: "",
  }
});

function generateData() {
  const data = dataMessage();
  data.source = "RandomDataGeneratorModule";
  data.shape = [props.rows, props.cols];
  data.type = "raw";
  data.config = {
    upperBound: props.upperBound,
    lowerBound: props.lowerBound,
  };
  data.meta = {
    xColor: randomColorArray(props.rows),
  };  
  // Populate the data values
  data.values = [];  
  for (let rowIndex = 0; rowIndex < props.rows; rowIndex++) {
    const row = [];
    for (let colIndex = 0; colIndex < props.cols; colIndex++) {
      row.push(randomInt(props.upperBound, props.lowerBound));
    }
    data.values.push(row);
  }
  // Add mock up additional fields to simulate the real data
  if (props.additionalFields != "") {
    let additionalFields = {};
    try {
      additionalFields = JSON.parse(props.additionalFields);
    } catch (error) {
      additionalFields = {};
    }

    for (const key in additionalFields) {
      if (Object.prototype.hasOwnProperty.call(additionalFields, key)) {
        data[key] = additionalFields[key];
      }
    }
  }
  events.emit('data', data);
  console.log("From number generate", data)
}
</script>

<style></style>
