<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Camera</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-label> Click the button below to capture or select an image</ion-label>
      <input type="file" id="imageInput" accept="image/*" class="hiddenFileInput" @input="handleFileCapture" />
    </ion-card-content>
    <ion-button fill="outline" expand="block" @click="triggerFileInput">Capture</ion-button>
  </ion-card>
</template>

<script setup>

import { events } from "@/utils/events";
import { dataMessage } from "@/messages/dataMessage";
import { ref } from "vue";

// Trigger file input when the Ionic button is clicked
function triggerFileInput() {
  document.getElementById('imageInput').click(); // Programmatically trigger the file input click
}

// This function is triggered when user selects the file.
function handleFileCapture(event) {
  // When user selects a file in an <input type="file" />
  // the event.target.files array holds all the files selected by the user
  // access the first file 
  const file = event.target.files[0];
  // Now free to work with  file
  console.log('file object is:')
  console.log(file)
  console.log('file size is:', file.size, 'Bytes')
  // Check if the file object is image
  if (!file || !file.type.startsWith('image/')) {
    alert('Please select a valid image file.');
    return;
  }
  // Create a new FileReader object for reading the file
  const reader = new FileReader();
  // Log the empty reader object
  console.log("Empty reader object is:", reader);
  // This is an event handler property that is triggered when the reading operation is successfully completed.
  reader.onload = () => {
    // Log the file reading is completed without any error
    console.log("File reading and conversion is completed without any error");
    // Log the encoded reader object
    console.log("Processed reader object is:", reader);
    const image = reader.result; // Assign Base64-encoded string to image variable
    const data = dataMessage();
    data.type = "image";
    data.source = "CameraModuleRaw";
    data.values = image;
    data.config = {};
    events.emit('data', data);
  };
  // Read the file as a Data URL and saves the result as object property reader.result
  reader.readAsDataURL(file);
  // Clear the file input's value to allow capturing or selecting the same file again
  event.target.value = "";
}
</script>

<style scoped>
.hiddenFileInput {
  display: none;
  /* Hides the default file input box */
}

</style>
