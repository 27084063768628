<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Display</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <img :src="imageSrc" />
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { events } from "@/utils/events";
import { ref, onBeforeUnmount, onMounted } from "vue";
const imageSrc = ref("");

onMounted(() => {
  events.on("data", onNewData);
  events.on("image", onNewData);
});

onBeforeUnmount(() => {
  events.off("data", onNewData);
  events.off("image", onNewData);
} );

function onNewData( dataMessage ) {

  if ( !dataMessage ) return;
  if ( dataMessage?.type !== 'image' ) return;
  if ( !dataMessage?.values?.startsWith('data:image/') ) return;
  console.log("ImageDisplayModule: ", dataMessage);        
  imageSrc.value = dataMessage.values;
}
</script>

<style></style>
